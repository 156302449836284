<template>
	<div class="control-panel">
		<div class="control-panel__left">
            <div class="selected">
                Выбрано {{selectedRows.length}} из {{ rowsCount }}
            </div>
		</div>
		<div class="control-panel__right">
			<BaseDropdown
				class="filter-panel__date-wrapper"
				:padding="0"
                @changeVisible="onChangeVisible"
			>
				<template #preview>
						<!-- :style="{marginLeft: '25px'}" -->
					<BaseButton
						class="calendar-btn"
						view="border"
					>
						<BaseIcon name="calendar" class="calendar-btn__icon" />
						<span class="calendar-btn__title">{{dateTitle}}</span>
					</BaseButton>
				</template>

				<template #content v-if="isOpenedDatepicker">
					<DatePicker
						:isMultiple="true"
                        :accessDates="prepareDates()"
                        :checkedDates="preparesSelectedDates"
                        @approve="OnSelectDates"
                        @cancel="onCloseDatepicker"
					/>
				</template>
			</BaseDropdown>


			<BaseButton
				:style="{marginLeft: '14px'}"
				:view="setModificationData.changeType === 'edit' ? 'secondary' : 'border'"
                @click="setChangeType('edit')"
			>Заменить</BaseButton>
			<BaseButton
				:style="{marginLeft: '10px'}"
				:view="setModificationData.changeType === 'decrease' ? 'secondary' : 'border'"
                @click="setChangeType('decrease')"
			>Уменьшить</BaseButton>

			<div :style="{margin: '0 14px'}">на</div>

			<div class="unit">
                <input
                    type="text"
                    class="unit__title"
                    v-model="setModificationData.changeValue"
                    @input="onInputChangeType"
                >
				<div class="unit__control">
					<div
                        class="unit-btn unit-btn-percent"
                        v-if="setModificationData.changeType === 'decrease'"
                        :class="setModificationData.valueType === 'percent' ? 'unit-btn_active' : ''"
                        @click="setValueType('percent')"
                    >%</div>
					<div
                        class="unit-btn"
                        :class="setModificationData.valueType === 'number' ? 'unit-btn_active' : ''"
                        @click="setValueType('number')"
                    >₽</div>
				</div>
			</div>
			<BaseButton
				:style="{marginLeft: '20px'}"
				view="border"
                @click="onCreateSetModification"
			>Применить</BaseButton>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"
import BaseIcon from "@/components/Base/BaseSvgIcon"
import BaseDropdown from "@/components/Base/BaseDropdown"
import DatePicker from "@/components/Base/DatePicker"

export default {
	name: "ControlPanel",
	components: {
		BaseButton,
		BaseIcon,
		BaseDropdown,
		DatePicker
	},
    data() {
        return {
            months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
            isOpenedDatepicker: false
        }
    },
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        rowsCount: {
            type: Number,
            default: 0
        },
        selectedRows: {
            type: Array,
            default: () => ([])
        },
        selectedDates:{
            type: Array,
            default: () => ([])
        },
        calendarDates:{
            type: Array,
            default: () => ([])
        },
        setModificationData: {
            type: Object,
            default: () => ({})
        }
    },
    computed:{
        preparesSelectedDates(){
            let selectedDates = [];
            this.selectedDates.forEach(date => {
                let split = date.split('-');
                selectedDates.push({year: Number(split[0]), month: Number(split[1])})
            });
            return selectedDates;
        },
        /**
         * заголовок для копки даты
         * @returns {String}
         */
        dateTitle() {
            if (this.preparesSelectedDates.length === 0) {
                return 'Выберите дату'
            } else {
                let uniqueMonths = []

                this.preparesSelectedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')
                return `${ result }`
            }
        },
    },
    methods:{
        prepareDates(){
            let selectedDates = [];
            this.calendarDates.forEach(date => {
                let split = date.split('-');
                selectedDates.push({year: Number(split[0]), month: Number(split[1])})
            });
            return selectedDates;
        },
        OnSelectDates(selected){
            this.$emit('changedDates', selected);
            this.isOpenedDatepicker = !this.isOpenedDatepicker;
        },
        onCloseDatepicker(){
            this.isOpenedDatepicker = !this.isOpenedDatepicker;
        },
        onChangeVisible(value){
            this.isOpenedDatepicker = value;
        },
        setChangeType(type){
            this.setModificationData.changeType = type;
            if(type === 'edit'){
                this.setModificationData.valueType = 'number';
            }
            this.setModificationData.changeValue = 0;
            this.$emit('changedModificationData', this.setModificationData);
        },
        setValueType(type){
            this.setModificationData.valueType = type;
            this.$emit('changedModificationData', this.setModificationData);
        },
        onInputChangeType() {
            this.setModificationData.changeValue = Number(this.setModificationData.changeValue.replace(/[^0-9]/g, ''));
            this.$emit('changedModificationData', this.setModificationData);
        },
        onCreateSetModification(){
            if(!this.selectedDates.length){
                alert('Вы не выбрали дату');
            }else if(!this.setModificationData.changeValue && this.setModificationData.valueType == 'number'){
                alert('Вы не ввели сумму');
            }else if(!this.setModificationData.changeValue && this.setModificationData.valueType == 'percent'){
                alert('Вы не ввели процент');
            }else if(!this.selectedRows.length){
                alert('Вы не выбрали строки');
            }
            if(this.setModificationData.changeValue > 0 && this.selectedDates.length && this.selectedRows.length){
                this.$emit('createSetModification');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.control-panel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	&__left {
		display: flex;
		align-items: center;
	}

	&__right {
		// margin-left: 100px;
		display: flex;
		align-items: center;
	}

	.selected {
		font-weight: 500;
	}
	.calendar-btn {
		display: flex;
		align-items: center;
		margin-left: 25px;

		&__title {
			margin-left: 10px;
		}
	}

	.unit {
		border: 1px solid $primary-color;
		height: 36px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		border-radius: 4px;

        &__title {
            width: 30px;
            display: block;
            border: none;
            outline: none;
        }

		&__control {
			margin-left: 10px;
			display: flex;

			.unit-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #B9BBC2;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: $primary-color;
				cursor: pointer;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&_active {
					background: $active-color;
					color: #fff;

                    &.unit-btn-percent {
                        background: $success-color;
                    }
				}
			}
		}

	}
}
</style>
