<template>
    <div
        v-show="isCurrent"
        class="layout-tab__body"
    >
        <div class="layout-tab__left">
            <div class="layout-tab__buttons">
                <div v-if="tableRows.length" class="layout-tab__buttons-left">
                    <BaseButton
                        view="secondary"
                        @click="onOpenCreateOfferPopup"
                    >Создать предложение</BaseButton>
                </div>
                <div class="layout-tab__buttons-right">
                    <BaseButton
                        v-if="tableRows.length"
                        view="border"
                        :style="{marginLeft: '10px'}"
                        @click="onRemoveSides"
                    >Удалить конструкции</BaseButton>
                    <BaseButton
                        v-if="tableRows.length"
                        view="border"
                        :style="{marginLeft: '10px'}"
                        @click="onExportFolder"
                    >Скачать Excel</BaseButton>
                    <BaseButton
                        view="border"
                        :style="{marginLeft: '10px'}"
                        @click="onRemoveTab"
                    >Удалить</BaseButton>
                </div>
            </div>

            <div class="layout-tab__control">
                <ControlPanel
                    :rowsCount="tableRows.length"
                    :selectedRows="selectedRows"
                    :calendarDates="tableColumnsDates"
                    :selectedDates="selectedDates"
                    :setModificationData="setModificationData"
                    @changedDates="onChangeSelectedDates"
                    @removeTab="onRemoveTab"
                    @changedModificationData="onChangeModificationData"
                    @createSetModification="onCreateSetModification"
                    @openOfferPopup="onOpenCreateOfferPopup"
                />
            </div>

            <div class="layout-tab__summary">
                <SummaryData
                    :summaryData="summaryData"
                    class="folder__summary"
                />
            </div>

            <div class="layout-tab__table-wrapper">
                <kit-budget-tab
                    :isProgressBarLoading="isProgressBarLoading"
                    :canCheck="true"
                    :columns="tableColumns"
                    :modifieredCols="tableColumnsModifered"
                    :datesCols="tableColumnsDates"
                    :tableData="tableRows"
                    :folderId="id"
                    :rowsCheckboxes="tableRowsPricesSelected"
                    class="layout-tab__table"
                    @onCheckRow="onCheckRow"
                    @onCheckRowsAll="onCheckRowsAll"
                    @filter="onFilter"
                    @sort="onSort"
                    @changeSinglePrice="onChangeSinglePrice"
                />
            </div>
        </div>
        <div class="layout-tab__right">
            <AcceptedChanges
                class="layout-tab__changes"
                :setModificationList="setModificationList"
                @onRemoveModification="onRemoveModification"
            />
        </div>
        <AppPopup
            v-if="isCreateOfferPopupOpen"
            @close="onCloseCreateOfferPopup"
        >
            <div class="modal">
                <div class="modal-title">Создание предложения</div>
                <div class="modal-subtitle">Введите логин пользователя</div>
                <input
                    type="email"
                    v-model="sendingEmail"
                    class="modal-input"
                >
                <div style="display: flex;align-items: center;">
                    <BaseButton
                        :style="{marginLeft: '20px'}"
                        @click="onCloseCreateOfferPopup"
                    >Отменить</BaseButton>
                    <BaseButton
                        :style="{marginLeft: '20px'}"
                        view="secondary"
                        @click="sendFolder"
                    >Отправить</BaseButton>
                </div>
            </div>
        </AppPopup>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import ServiceApi from '@/services/ServiceApi.js';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinSummary from '@/mixins/MixinSummary';
import KitBudgetTab from '@/components/HandbookComponents/KitBudgetTab';
import ControlPanel from "@/components/PricePageComponents/ControlPanel";
import AcceptedChanges from "@/components/PricePageComponents/AcceptedChanges";
import AppPopup from "@/components/Popup";
import BaseButton from "@/components/Base/BaseButton";
import SummaryData from "@/components/Folder/SummaryData";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";

export default {
    name: 'LayoutTab',
    components: {
        BaseButton,
        KitBudgetTab,
        ControlPanel,
        AppPopup,
        SummaryData,
        AcceptedChanges
    },
    mixins: [
        MixinTableFound,
        MixinSummary,
        MixinApi
    ],
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        id: {
            type: String,
            default: '-1'
        },
        isCurrent: {
            type: Boolean,
            default: false
        },
        title:{
            type: String,
            default: ''
        }
    },
    /**
     * @property {Array} selectedRows - выделенные строки
     * @property {Array} selectedDates - выбранные даты (столбцы)
     * @property {Array} selectedDatesObjs - выбранные даты (столбцы) в виде объекта {year:2021,month:5}
     * @property {Object} setModificationData - настройки панели изменения цена
     * @property {Array} setModificationList - список модификаций
     */
    data() {
        return {
            selectedRows: [],
            selectedDates: [],
            selectedDatesObjs: [],
            setModificationData: {
                changeType: 'edit',
                valueType: 'number',
                changeValue: 0
            },
            setModificationList: [],
            isCreateOfferPopupOpen: false,
            sendingEmail: '',
            isProgressBarLoading: false
        }
    },
    computed: {
        /**
         * Тело запроса для таблицы
         * @returns {Object}
         */
        tableRequestBody() {
            const tableRequestBody = {
                set_id: this.id,
                sort: this.tableSort,
                filter: this.tableFilter,
            };
            return tableRequestBody;
        },
        /**
         * Тело базового запроса
         * @returns {Object}
         */
        baseRequestBody() {
            const setRequestBody = {
                set_id: this.id,
            };
            return setRequestBody;
        },
        /**
         * Тело запроса для применения модификаций к выбранным столбцам
         * @returns {Object}
         */
        addSetPriceRequestBody() {
            const setPriceRequestBody = {
                set_id: this.id,
                dates: this.selectedDates,
                selected: this.selectedRows,
                change_type: this.setModificationData.changeType,
                value_type: this.setModificationData.valueType,
                change_value: this.setModificationData.changeValue
            };
            return setPriceRequestBody;
        },
        /**
         * Выбранные цены набора
         * @returns {Object}
         */
        tableRowsPricesSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {date = ''} = price;
                    return date;
                })
            });
            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsPricesIdsSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {id = '-1'} = price;
                    return id;
                })
            });
            return tableRowsPricesSelected;
        }
    },
    watch: {
        tableColumnsDates(){
            if(this.tableColumnsDates.length){
                //Устанавливаю последнюю дату по умолчанию
                let lastDate = this.tableColumnsDates[this.tableColumnsDates.length - 1];
                this.selectedDates.push(lastDate);
            }
        },
        /**
         * Следим является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        isCurrent() {
            if (this.isCurrent) {
                this.isProgressBarLoading = true;
                if(this.tableRows.length === 0) {
                    this.postTableBudgetDataCustomKit((rows) => {
                        this.afterPostTableBudgetData(rows, 'prices');
                        this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                        this.setTableCustomRows(this.tableRows);
                    });
                }
                else {
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                }
                if(this.setModificationList.length === 0){
                    this.postSetModificationsData((list) => {
                        this.setModificationList = list;
                        this.isProgressBarLoading = false;
                    })
                }
                this.postSummaryData();
            }
        }
    },
    mounted() {
        /**
         * При монтировании проверяем является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        if (this.isCurrent) {
            if(this.tableRows.length === 0) {
                this.$emit('onLoading', true);
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices');
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                    this.$emit('onLoading', false);
                });
            }
            else {
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
            if(this.setModificationList.length === 0){
                this.postSetModificationsData((list) => {
                    this.setModificationList = list;
                })
            }
            this.postSummaryData();
        }
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        async postSummaryData() {
            try {
                const response = await ServiceApi.post('free', '/map/set-summary', {set_id: this.id});
                const result = response.data.data
                this.summaryData = this.AdapterSummaryData(result)
            } catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableBudgetDataCustomKit(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-table-budget', this.tableRequestBody);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = []} = dataCustom;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSetModificationsData(actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-modifications', this.baseRequestBody);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                actionAfter(dataCustom);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на применение модификаций к выбранным ценам
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         * @param {Object|Boolean} outData
         */
        async postSetPriceModificationCreate(actionAfter = () => {}, outData = false) {
            if(outData === false){
                outData = this.addSetPriceRequestBody;
            }
            try {
                const res = await ServiceApi.post('free', '/map/set-add-modification', outData);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = [], modification = {}} = dataCustom;
                actionAfter(rows, modification);
            }
            catch(error) {
                console.log(error);
            }
        },
        /**
         * Запрос на удалении модификации
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         * @param {Number} id
         */
        async postSetPriceModificationRemove(actionAfter = () => {}, id) {
            try {
                const res = await ServiceApi.post('free', '/map/set-remove-modification', {set_modification_id: id});
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = []} = dataCustom;
                actionAfter(rows);
            }
            catch(error) {
                console.log(error);
            }
        },
        onChangeSelectedDates(dates){
            this.selectedDates = [];
            dates.forEach(item => {
                let strMonth = item.month > 9 ? item.month : "0" + item.month;
                this.selectedDates.push(item.year + "-" + strMonth + "-01");
            })
        },
        /**
         * Получение данных для таблицы набора
         */
        updateDataCustomKit() {
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true);
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            });
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         */
        onCheckRow(checkData = {}) {
            // console.log(this.$refs.datesCols);
            this.selectedRows = checkData.rowsIdsSelected;
        },
        onCheckRowsAll(isSelected = false) {
            if (isSelected) {
                this.selectedRows = this.tableRows.map(row => {
                    const {id = '-1'} = row;
                    return String(id);
                });
            }
            else {
                this.selectedRows = [];
            }
        },
        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilter(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true);
            });
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        onSort(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true);
            });
        },
        /**
         * Добавить цены в таблицу
         * @property {Array} rows - строки
         */
        addPriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const currentRowsIds = this.tableRows.map(row => String(row.id));
                    const {id = '-1'} = row;
                    if (!currentRowsIds.includes(id)) {
                        this.afterPostTableBudgetData(rows, 'prices')
                    }
                    else {
                        const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                        const rowsAdapted = this.adapterRows([row], 'prices');
                        if (index !== -1) {
                            this.tableRows.splice(index, 1, rowsAdapted[0]);
                            const columnsDatesAdapted = this.adapterColumnsDates([row], 'prices');
                            this.collectBudgetColumns(columnsDatesAdapted);
                        }
                    }
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                });
            }
        },
        /**
         * Удалить цены из таблицу
         * @property {Array} rows - строки
         */
        removePriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const {id = '-1'} = row;
                    const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                    const rowsAdapted = this.adapterRows([row], 'prices');
                    if (index !== -1) {
                        this.tableRows.splice(index, 1, rowsAdapted[0]);
                        const columnsDatesAdapted = this.adapterColumnsDates([row], 'prices');
                        this.collectBudgetColumns(columnsDatesAdapted);
                    }
                });
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Удалить строку из таблицы
         * @property {String} rowId - идентификатор набора
         */
        removeRowTable(rowId = '-1') {
            if (this.isCurrent) {
                const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(rowId));
                if (index !== -1) {
                    this.tableRows.splice(index, 1);
                    const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'prices');
                    this.collectBudgetColumns(columnsDatesAdapted);
                }
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Событие удаления набора
         */
        onRemoveTab() {
            const response = confirm("Вы уверены?")

            if (response) {
                this.$emit('removeSet', String(this.id));
            }
        },
        /**
         * Событие изменения настроек панели
         */
        onChangeModificationData(data){
            this.setModificationData = data;
        },
        /**
         * Создание модификации
         */
        onCreateSetModification(){
            this.postSetPriceModificationCreate((rows, modification) => {
                this.afterPostTableBudgetData(rows, 'prices', true);
                this.setModificationList.unshift(modification);
                this.postSummaryData();
            })
        },
        /**
         * Редактирование ячейки
         * @param {Object} data
         */
        onChangeSinglePrice(data){
            this.postSetPriceModificationCreate((rows, modification) => {
                this.afterPostTableBudgetData(rows, 'prices', true);
                this.setModificationList.unshift(modification);
                this.postSummaryData();
            }, {
                set_id: this.id,
                dates: data.dates,
                selected: data.selected,
                change_type: 'edit',
                value_type: 'number',
                change_value: data.changeValue
            })
        },
        /**
         * Удаление модификации
         * @param {Number} id
         */
        onRemoveModification(id){
            this.postSetPriceModificationRemove((rows) => {
                if(rows.length){
                    this.afterPostTableBudgetData(rows, 'prices', true);
                }
                this.setModificationList = this.setModificationList.filter(item => item.id != id);
                this.postSummaryData();
            }, id)
        },
        onOpenCreateOfferPopup() {
            this.isCreateOfferPopupOpen = true
        },
        onCloseCreateOfferPopup() {
            this.isCreateOfferPopupOpen = false
        },
        sendFolder(){
            this.postSendKit(this.sendingEmail);
            this.sendingEmail = '';
            this.onCloseCreateOfferPopup();
        },
        /**
         * @param {String} sendingEmail - новое название
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSendKit(sendingEmail = '', actionAfter = () => {}) {
            const postData = {set_name: this.title, set_id: this.id, email: sendingEmail, sender_mail: 'bigboss_34@oohdesk.ru'};
            try {
                const res = await ServiceApi.post('demo', '/base_catalog/buyer/create-offer', postData);
                const {data = {}} = res;
                if(data.message){
                    alert(data.message);
                }
                actionAfter();
            }
            catch(error) {
                console.log(error);
            }
        },
        onExportFolder() {
            this.postExportKit(this.id, this.afterPostExportKit)
        },
        onRemoveSides() {
            const response = confirm("Вы уверены что хотите удалить конструкции из набора?")
            if(!response){
                return;
            }
            const postData = {set_id: this.id, action: 'remove-sides', ids: this.selectedRows};
            this.$emit('onLoading', true);
            this.postSyncDataCustom(postData, () => {
                this.selectedRows = [];
                this.tableRows = [];
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices');
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                    this.postSummaryData();
                    this.$emit('onLoading', false);
                });
            });
        },
        /**
         * Запрос для экспорта набора
         * @param {Number} kitId - идентификатор набора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postExportKit(kitId = -1, actionAfter = () => {}) {
            const postData = {set_id: kitId}
            try {
                const res = await ServiceApi.post('free', '/export/set', postData);
                const {data = {}} = res;
                const {data: obj = {}} = data;
                actionAfter(obj.url);
            }
            catch(error) {
                console.log(error);
            }
        },
        afterPostExportKit(link){
            window.open(link);
        },
    }
}
</script>

<style lang="scss" scoped>
.modal{
    background: white;
    padding: 35px;

    .modal-title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .modal-subtitle {
        font-size: 14px;
    }

    .modal-input{
        padding: 5px;
        margin: 15px 0px;
        width: 100%;
    }
}

.layout-tab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    &__buttons-left{
        display: flex;
        align-items: center;
    }

    &__buttons-right{
        margin-left: 100px;
        display: flex;
        align-items: center;
    }

    &__summary{
        padding: 20px 10px 0 10px;
    }

    &__body {
        margin-top: 40px;
        display: flex;
        flex-grow: 1;
        overflow: hidden;
    }
    &__table-wrapper {
        margin-top: 20px;
        // height: 100%;
        flex-grow: 1;
        overflow: hidden;
    }
    &__table {
        display: inline-block;
        height: 100%;
        max-width: 100%;
    }
    &__left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__right {
        margin-left: 20px;
    }
    &__changes {
        height: 100%;
    }
}
</style>
