<template>
	<div class="changes">
		<div class="changes__title">Модификации</div>
        <div 
            v-if="setModificationList.length === 0"
            class="changes__msg_empty"
        >
            Модификации отсутствуют
        </div>
		<div class="changes__list">
            <div
                v-for="(item, index) of setModificationList"
                :key="index"
                class="change"
                :class="getChangeClasses(item.change_type)"
            >
                <div class="change__info">
                    <div class="change__title">{{item.name}}</div>
                    <div class="change__value">{{getSign(item)}}</div>
                </div>
                <div
                    class="change__close"
                    @click="removeModification(item.id)"
                >
                    <BaseIcon icon="icon-delete" class="change__close-icon" />
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon"

export default {
	name: "AcceptedChanges",
	components: {
		BaseIcon
	},
    /**
     * Входные данные
     */
    props: {
        setModificationList:{
            type: Array,
            default: () => ([])
        }
	},
	methods: {
		getChangeClasses(type) {
			return {
				success: type === 'enlarge',
				danger: type === 'decrease',
                primary: type === 'edit'
			}
		},
        getSign(item){
            let sign = item.change_value != '' ? parseInt(item.change_value).toLocaleString('ru') : '';
            if(item.change_type === 'enlarge'){
                sign = '+ ' + sign;
            }
            if(item.change_type === 'decrease'){
                sign = '- ' + sign;
            }
            if(item.value_type === 'percent'){
                sign = sign + ' %';
            }
            if(item.value_type === 'number'){
                sign = sign + ' ₽';
            }
            return sign;
        },
        removeModification(id){
            this.$emit('onRemoveModification', Number(id));
        }
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.changes {
	width: 211px;
	border-radius: 10px;
	background: $primary-color;
	display: inline-block;
	padding: 20px;
    &__msg_empty {
        margin-top: 20px;
        color: #ADB0BB;
    }

	&__title {
		font-weight: 500;
	}

	&__list {
		margin-top: 20px;
	}

	.change {
		background: #FFFFFF;
		padding: 12px 10px 12px 15px;
		display: flex;
		align-items: center;
		position: relative;
		border-radius: 10px;

		&:not(:last-child) { margin-bottom: 10px; }

		// &.danger .change__value {
		// 	color: $danger-color;
		// }

		// &.success .change__value {
		// 	color: $success-color;
		// }

		&__info {
			// display: flex;
			font-size: 10px;
		}

		&__title {
			width: 120px;
		}
		&__value {
			// width: 70px;
			// text-align: right;
			// padding-right: 10px;
			margin-top: 4px;
			color: $assistant-color;
		}
		&__close {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
			width: 24px;
			height: 24px;
			background: $primary-lighten-color;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&:hover {
				color: $danger-color;
			}

			&-icon {
				font-size: 12px;
				color: $assistant-color;
			}
		}
	}
}
</style>
