<template>
	<div class="all">
		<AppTable
			primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="true"
			:columns="columns"
			:rows="tableData"
			:isProgressBar="isProgressBarLoading"
            :rowsSelected="rowsSelected"
            :rowsSelectedPart="rowsSelectedPart"
			class="all-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
		>
			<template
				v-for="(col,x) of [...modifieredCols, ...datesCols]"
				v-slot:[col]="scopeData"
			>
				<BaseCell
                    v-if="modifieredCols.includes(col)"
					:key="x"
					:content="scopeData.row[col]"
				/>
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                >
                    <label class="checkbox-label">
						<div class="cell-checkbox">
							<base-checkbox-v2
								v-if="scopeData.row[col]"
								:key="x"
								:checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
							/>
						</div>
						<div
							class="cell-checkbox2"
							:class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB'
                            }"
						>
							<div v-if="typeof scopeData.row[col] === 'object'">
                                <div
                                    v-if="!isCellEditing(col, scopeData.row.id)"
                                    @dblclick="onClickToEdit($event, col, scopeData.row.id)"
                                >
                                    <span
                                        v-if="scopeData.row[col].offer_price && scopeData.row[col].offer_price != scopeData.row[col].price && !isCellEditing(col, scopeData.row.id)"
                                    >
                                        <p
                                            style="text-decoration:line-through;font-size: 10px"
                                        >{{scopeData.row[col].price != '' ? parseInt(scopeData.row[col].price).toLocaleString('ru') : ''}}</p>
                                        <p
                                            :style="scopeData.row[col].price < scopeData.row[col].offer_price ? 'color:red' : 'color:green'"
                                        >{{scopeData.row[col].offer_price != '' ? parseInt(scopeData.row[col].offer_price).toLocaleString('ru') : ''}}</p>
                                    </span>
                                    <span v-else>
                                        {{scopeData.row[col].price != '' ? parseInt(scopeData.row[col].price).toLocaleString('ru') : ''}}
                                    </span>
                                </div>
                                <!-- <div
                                    contenteditable
                                    v-show="isCellEditing(col, scopeData.row.id)"
                                    @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    v-tooltip="getEditingValue(scopeData.row[col])"
                                    v-clipboard="() => getEditingValue(scopeData.row[col])"
                                    @blur="onBlur($event, col, scopeData.row)"
                                >
                                    {{getEditingValue(scopeData.row[col])}}
                                </div> -->
                                <div
                                    v-else
                                >
                                    <base-text-input
                                        ref="baseTextInput"
                                        :value="String(getEditingValue(scopeData.row[col]))"
                                        @onBlur="onBlurV2($event, col, scopeData.row)"
                                        @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    />
                                </div>
                            </div>
                            <span v-else>
                                <!-- <img
                                    :style="{'width': '15px', 'heigth': '15px'}"
                                    src="@/assets/images/icons/hui.svg"
                                > -->
                                <div :style="{'width': '15px', 'heigth': '15px', 'background': '#dce1e8'}" />
                            </span>
						</div>
					</label>
                </div>
			</template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
				/>
			</template>

			<template v-slot:light="scopeData">
				<AppLight :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.image_link" />
			</template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>
		</AppTable>
	</div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';
import BaseTextInput from '@/components/Base/BaseTextInput';

export default {
	name: "KitBudgetTab",
    mixins: [MixinHandbookTab],
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BaseCheckboxV2,
        BaseTextInput
	},
    /**
     * @property {Object} editingCell - координаты редактируемой ячейки
     */
    data: () => ({
        editingCell: {}
    }),
	props: {
        datesCols: {
            type: Array,
			default: () => ([])
        },
        rowsSelected: {
            type: Array,
			default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
		folderId: {
			type: [Number, String],
			required: true
		},
        canCheck: {
            type: Boolean,
            default: false
        },
        isProgressBarLoading: {
            type: Boolean,
            default: false
        }
	},
	// computed: {},
    methods: {
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        // onCheckSubCheckbox(checkData = {}) {
        //     this.$emit('onCheckSubCheckbox', checkData);
        // },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxes[rowId] || [];
            return cellsChecked.includes(prop);
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null;
        },
        /**
         * Включаю возможность редактирования
         */
        onClickToEdit($event, col, row) {
            this.editingCell = {
                col: col,
                row: row
            };
            // console.log($event.target);
            $event.target.focus()
            // setTimeout(() => {
            //     $event.target.focus()
            // }, 0)
            this.$nextTick(() => {
                this.$refs.baseTextInput[0].onFocus();
            })
        },
        /**
         * Эмичу новое значение
         */
        onBlur($event, col, rowObj) {
            if(!this.isCellEditing(col, rowObj.id)){
                return;
            }
            const content = $event.target.textContent;
            this.editingCell = {};
            this.changeValue({
                selected: [rowObj.id],
                dates: [col],
                changeValue: content,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        onBlurV2(event, col, rowObj) {
            const content = event === null ? '' : event.target.value;
            if(!this.isCellEditing(col, rowObj.id)){
                return;
            }
            this.editingCell = {};
            this.changeValue({
                selected: [rowObj.id],
                dates: [col],
                changeValue: content,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        /**
         * Эмитит измененное значение ячейки
         */
        changeValue(data) {
            data.changeValue = Number(data.changeValue.replace(/[^0-9]/g, ''));
            if(data.changeValue != data.oldValue){
                this.$emit('changeSinglePrice', data);
            }
        },
        /**
         * Завершаю редактирование при нажатии на "enter"
         */
        onFinishEdit($event, col, row) {
            if(!this.isCellEditing(col, row)){
                return;
            }
            $event.target.blur()
            this.editingCell = {};
        },
        isCellEditing(col, row){
            let flag = Object.keys(this.editingCell).length !== 0 && this.editingCell.col == col && this.editingCell.row == row;
            return flag;
        },
        getEditingValue(row){
            let value = row.offer_price ? row.offer_price : row.price;
            return value;
        }
    },
	filters: {
		cutNumber(value = '') {
            if (value !== '') {
                const priceLocale = parseInt(value).toLocaleString('ru');
                const priceLocaleArr = String(priceLocale).split(/\s/);
                const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                return priceFormatted;
            }
		}
	}
}
</script>

<style lang="scss" scoped>

.all {
	display: flex;

	&-table {
		margin-top: 4px;
	}

	.checkbox-label {
		.cell-checkbox { display: none !important; }

		.cell-checkbox2 {
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid;
			border-color: #F4F6F9;
			border-radius: 4px;
		}
	}
}
</style>

<style lang="scss">
.all .table .header .date-cell .cell__content span {
	display: block;
	text-align: center;
	width: 100%;
}

.all .table .body .gid-cell .default-cell,
.all .table .body .supplier-cell .default-cell,
.all .table .body .direction-cell .default-cell {
	max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.all-table {
    width: 100%;
	//padding-top: 61px !important;
}
</style>
